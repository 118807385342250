<template>
  <div style="position: relative">
    <div class="button-box" v-drag @mousedown="mousedowm" @mouseup="mouseup">
      <div class="btn-bg-img">
        <div class="isCollapse el-icon-s-unfold"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DraggableTag',
  data() {
    return {
      isOpen: false,
      isMove: false,
      clientOffset: {},
    }
  },
  methods: {
    mousedowm(e) {
      this.clientOffset.clientX = e.clientX
      this.clientOffset.clientY = e.clientY
    },
    mouseup(e) {
      const clientX = e.clientX
      const clientY = e.clientY
      if (clientX === this.clientOffset.clientX && clientY === this.clientOffset.clientY) {
        this.$emit('changeIsCollapse', false)
      }
    },
  },
  directives: {
    drag(el) {
      let oDiv = el // 当前元素
      oDiv.onmousedown = function (e) {
        // 鼠标按下，计算当前元素距离可视区的距离
        let disX = e.clientX - oDiv.offsetLeft
        let disY = e.clientY - oDiv.offsetTop
        document.onmousemove = function (e) {
          // 通过事件委托，计算移动的距离
          let l = e.clientX - disX
          let t = e.clientY - disY
          // 移动当前元素
          oDiv.style.left = l + 'px'
          oDiv.style.top = t + 'px'
        }
        document.onmouseup = function (e) {
          document.onmousemove = null
          document.onmouseup = null
        }
        // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false
      }
    },
  },
}
</script>

<style scoped>
.button-box {
  width: 60px;
  border-radius: 100%;
  position: fixed;
  bottom: 60px;
  left: 20px;
  padding-left: 15px;
  padding-top: 8px;
  cursor: pointer;
  opacity: 0.7;
  z-index: 888;
}
.btn-bg-img {
  position: relative;
  height: 60px;
  width: 60px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.24);
  text-align: center;
  line-height: 40px;
  color: #606266;
  border-radius: 100%;
  background-color: #fff;
}
.button-box:hover {
  color: white;
  opacity: 1;
}

.isCollapse {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  text-align: center;
  cursor: pointer;
}
</style>
